// src/store.js

import { createStore } from 'vuex';

export default createStore({
  state: {
    // Define your state properties here
    count: 0,
    iframeSrc: '', // New state variable
    token: {},
    tokenExp: {},
    client: {},
  },
  mutations: {
    // Define your mutations to change state
    increment(state) {
      state.count++;
    },
    decrement(state) {
      state.count--;
    },
    setIframeSrc(state, src) { // New mutation
      state.iframeSrc = src;
    },
    saveToken(state, payload) {
      state.token = payload
    },
    saveTokenExp(state, payload) {
      state.tokenExp = payload
    },
    saveClient(state, payload) {
      state.client = payload
    },
  },
  actions: {
    // Define your actions to commit mutations
    increment({ commit }) {
      commit('increment');
    },
    decrement({ commit }) {
      commit('decrement');
    },
    updateIframeSrc({ commit }, src) { // New action
      commit('setIframeSrc', src);
    },
  },
  getters: {
    // Define your getters to access state properties
    getCount: (state) => state.count,
    getIframeSrc: (state) => state.iframeSrc, // New getter
  },
  modules: {
    // Define your modules if you have any
  },
});
