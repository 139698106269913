import * as msal from '@azure/msal-browser';
import azureConfig from '@/config/msalConfig';
import axios from 'axios';
import store from '@/store';
import config from '@/config';
import getDate from "./../helperFunctions/getDate";

const msalInstance = new msal.PublicClientApplication(azureConfig.msalConfig);

const signIn = async () => {
  try {
    await msalInstance.initialize();
    const redirectResponse = await msalInstance.handleRedirectPromise();
    console.log("Redirect response:", redirectResponse);

    const accounts = msalInstance.getAllAccounts();
    console.log("Accounts:", accounts);

    if (accounts.length === 0) {
      console.log("No accounts found, redirecting to login...");
      await msalInstance.loginRedirect({
        scopes: ["user.read"], // Add the scopes you need here
      });
    } else {
      console.log("Accounts found, no need to redirect.");
    }
  } catch (error) {
    console.error("Error in signIn:", error);
  }
};

const getUserProfile = async () => {
  try {
    await msalInstance.initialize();
    const redirectResponse = await msalInstance.handleRedirectPromise();
    console.log("Redirect response:", redirectResponse);

    const accounts = msalInstance.getAllAccounts();
    console.log("Accounts:", accounts);

    if (accounts.length === 0) {
      console.log("No accounts found, opening login popup...");
      await signIn();
      return null;
    } else if (accounts.length === 1) {
      const account = accounts[0];
      const silentRequest = {
        account: account,
        scopes: ["user.read"]
      };

      try {
        const silentResult = await msalInstance.acquireTokenSilent(silentRequest);
        console.log("Silent token acquisition successful:", silentResult);
        return account;
      } catch (silentError) {
        console.log("Silent token acquisition failed, falling back to interactive login:", silentError);
        await signIn();
        return null;
      }
    } else {
      console.log("Multiple accounts found:", accounts);
      const account = accounts[0]; // Return the first account as a default behavior
      const silentRequest = {
        account: account,
        scopes: ["user.read"]
      };

      try {
        const silentResult = await msalInstance.acquireTokenSilent(silentRequest);
        console.log("Silent token acquisition successful:", silentResult);
        return account;
      } catch (silentError) {
        console.log("Silent token acquisition failed, falling back to interactive login:", silentError);
        await signIn();
        return null;
      }
    }
  } catch (error) {
    console.error("Error in getUserProfile:", error);
    return null;
  }
};

const signOut = async () => {
  try {
    await msalInstance.initialize();
    const accounts = msalInstance.getAllAccounts();
    console.log("Accounts before sign out:", accounts);

    if (accounts.length > 0) {
      console.log("Redirecting to logout...");
      await msalInstance.logoutRedirect();
    } else {
      console.log("No accounts found, no need to sign out.");
    }
  } catch (error) {
    console.error("Error in signOut:", error);
  }
};

const getToken = async () => {
  try {
    let getTokenResponse = await axios.post(
      config.serviceauthendpoint,
      config.authCreds
    );
    if ((getTokenResponse.data.status == 200)) {
      let tokenExp = await getTokenResponse.data.expires_at;
      let tokenDate = new Date(tokenExp);
      store.commit("saveToken", getTokenResponse.data.token);
      store.commit("saveTokenExp", tokenDate);
      axios.defaults.headers.common["token"] = store.state.token;
      axios.defaults.headers.common["client_id"] = config.authCreds.company_id;
      return 'success';
    } else {
      return 'success';
    }
  } catch (error) {
    return 'error';
  }
};

const checkToken = async () => {
  let token = store.state.token;
  if (token.length == undefined) {
    try {
      await getToken();
      return 'success';
    } catch (error) {
      return 'error';
    }
  } else {
    try {
      let tokenExp = store.state.tokenExp;
      let dateNow = getDate.getDate();
      if (dateNow > tokenExp) {
        await getToken();
        return 'success';
      }
      axios.defaults.headers.common["token"] = store.state.token;
      axios.defaults.headers.common["client_id"] = config.authCreds.company_id;
      return 'success';
    } catch (error) {
      return 'error';
    }
  }
};

export { signIn, getUserProfile, signOut, getToken, checkToken };
