export default {
    msalConfig: {
        auth: {
            clientId: process.env.VUE_APP_AZURE_CLIENT_ID, // Replace with your Azure AD client ID
            authority: 'https://login.microsoftonline.com/common', // Replace with your Azure AD tenant ID
            redirectUri: process.env.VUE_APP_REDIRECT_URL // Replace with your redirect URI
        },
        cache: {
            cacheLocation: 'localStorage', // This configures where your cache will be stored
            storeAuthStateInCookie: false // Set this to true if you are having issues on IE11 or Edge
        }
    },
    authorizedTenants: [
        'f4ec0363-ab41-46f1-b0b2-7f4236778cbb',
        // Add more tenant IDs as needed
    ],
    clientTenantMappings: {
        "7nigzkxzdm9zg6blz6kr": "220f59f7-733b-40ae-aac4-7770b8065820", //Abstrax
        "zxR4UyMh9zuMJx4UuD4R": "15c0e2d9-ea17-4f45-aa46-23564bdf04de", //BigRentz
    },
}