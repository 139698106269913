/*eslint-disable*/
import { createRouter, createWebHistory } from 'vue-router';
// import authentication from '../authentication'
import msalConfig from '../config/msalConfig'
import { signIn, getUserProfile, signOut } from '@/authentication';

const findKeyByValue = (object, value) => {
    return Object.keys(object).find(key => object[key] === value);
};

const routes = [
    {
        path: '/',
        name: 'LandingDashboard',
        component: () => import('./../components/LandingDashboard.vue'),
        beforeEnter: async (to, from, next) => {
            let prof = await getUserProfile()
            const companyID = findKeyByValue(msalConfig.clientTenantMappings, prof.tenantId);
            if (msalConfig.authorizedTenants.includes(prof.tenantId)) {
                next()
            } else if (msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next(`/${companyID}/directory`)
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/billable',
        name: 'BillableUsers',
        component: () => import('./../components/user/BillableUsers.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/onboarded',
        name: 'OnboardedUsers',
        component: () => import('./../components/user/OnboardedUsers.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/offboarded',
        name: 'OffboardedUsers',
        component: () => import('./../components/user/OffboardedUsers.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/contractors',
        name: 'ContractorsUsers',
        component: () => import('../components/user/ContractorsUsers.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/directory',
        name: 'DirectoryUsers',
        component: () => import('../components/user/DirectoryUsers.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/deployed',
        name: 'DeployedAssets',
        component: () => import('../components/asset/DeployedAssets.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/deployable',
        name: 'DeployableAssets',
        component: () => import('../components/asset/DeployableAssets.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/tickets/open',
        name: 'OpenTickets',
        component: () => import('../components/ticket/OpenTickets.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/tickets/created',
        name: 'CreatedTickets',
        component: () => import('../components/ticket/CreatedPast30Tickets.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/tickets/completed',
        name: 'ResolvedTickets',
        component: () => import('../components/ticket/ResolvedPast30Tickets.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/user/onboard',
        name: 'UserOnboard',
        component: () => import('../components/user/UserOnboard.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/user/offboard',
        name: 'UserOffboard',
        component: () => import('../components/user/UserOffboard.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/archive/records',
        name: 'ArchiveRecords',
        component: () => import('../components/archive/ArchiveRecords.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/:companyID/archive/jobs',
        name: 'ArchiveJobs',
        component: () => import('../components/archive/ArchiveJobs.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            var companyID = to.params.companyID;
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId) || msalConfig.clientTenantMappings[companyID] == prof.tenantId) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/dashboard/tickets',
        name: 'TicketDashboard',
        component: () => import('../components/TicketDashboard.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId)) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    {
        path: '/dashboard/metrics',
        name: 'MetricDashboard',
        component: () => import('../components/MetricDashboard.vue'),
        props: true,
        beforeEnter: async (to, from, next) => {
            let prof = await getUserProfile()
            if (msalConfig.authorizedTenants.includes(prof.tenantId)) {
                next()
            } else {
                window.location.href = 'https://www.wiseguyz.com'; // Replace with the URL you want to redirect to
            }
        }
    },
    // {
    //     path: '/unauthorized',
    //     name: 'UnAuthorized',
    //     component: () => import('../components/UnAuthorized.vue'),
    //     beforeEnter: async (to, from, next) => {
    //         await getUserProfile()
    //         next()
    //     }
    // },
    // Add more routes as needed
];

const router = createRouter({
    history: createWebHistory(), // Using createWebHistory instead of mode: 'history'
    routes
});

export default router;

