import { createApp } from 'vue';
import App from './App.vue';
import router from './router'; // Import the router
import store from './store'; //Import the store


import "bootstrap/dist/css/bootstrap.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import "bootstrap/dist/js/bootstrap.bundle.min.js"; // Import Bootstrap's JavaScript


const app = createApp(App);
app.use(router); // Use the router
app.use(store); // use the store

app.mount('#app');
